
import { computed, ref } from 'vue';

// icons
import { compass, informationCircle, location, call, print, mail, documentText,
        scan, add, qrCodeOutline, close, trendingUp, construct, checkmarkDone,
        home, build, documentsOutline, peopleCircle, logoFacebook, } from 'ionicons/icons';

// swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, Pagination, } from 'swiper';

// components
import { IonicSlides, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonModal,
        IonGrid, IonCol, IonRow, IonSpinner, IonImg, IonSegment, IonSegmentButton, IonLabel, IonAvatar,
        IonButtons, IonButton, IonIcon, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip,
        modalController, } from '@ionic/vue';
import ProjectCard from "@/components/ProjectCard.vue";
import LogoImg from "@/components/LogoImg.vue";
import QualificationModal from '@/components/modals/QualificationModal.vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import config from '@/config';
import { utils } from '@/composables/utils';
import { useQRCodeScanner } from '@/composables/useQRCodeScanner';

import { Browser } from '@capacitor/browser';

export default {
  name: 'HomePage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonModal,
                IonGrid, IonCol, IonRow, IonSpinner, IonImg, IonSegment, IonSegmentButton, IonLabel, IonAvatar,
                IonButtons, IonButton, IonIcon, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip,
                Swiper, SwiperSlide, ProjectCard, LogoImg, },
  setup() {
    // methods
    const { t, locale, } = useI18n();
    const store = useStore();
    const { PROJECT_STATUSES, addResizeUrlParams, } = utils();
    const { scanningQRCode, startScanLocationQRCode, stopScan, } = useQRCodeScanner();

    // state variables
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData || store.state.loadingProjects);
    const loadingUser = computed(() => store.state.loadingUser);
    const involvingProjects = computed(() => store.getters.userInvolvingProjects);
    const projectsRelatedToUsers = computed(() => store.getters.projectsRelatedToUsers);
    const userLocations = computed(() => store.state.userLocations);
    const latestProjects = computed(() => store.getters.latestProjects);
    const inProgressProjects = computed(() => store.getters.getProjectsByStatus(PROJECT_STATUSES.inProgress));
    const completedProjects = computed(() => store.getters.getProjectsByStatus(PROJECT_STATUSES.completed));
    const companyInfo = computed(() => store.state.companyInfo);
    const qualifications = computed(() => store.getters.groupedQualifications);
    const isMobileApp = computed(() => store.state.isMobileApp);

    // Project List modal
    const isProjectListModalOpened = ref(false);
    const selectedProjectType = ref("");

    // Swiper
    const tabSlideOps = {
      slidesPerView: 2,
      freeMode: true,
      autoHeight: true,
      loop: false,
      autoplay: { delay: 6000, },
      spaceBetween: 0,
      breakpoints: {
        768: { slidesPerView: 3, },
        1024: { slidesPerView: 4, }
      }
    };
    const openBrowser = async (url: any) => {
      await Browser.open({ url, toolbarColor: config.primaryColor });
    }

    const openQualificationModal = async (title: any, qualifRows: any, companyInfo: any) => {
      const modal = await modalController.create({
        component: QualificationModal,
        componentProps: { qualification: { title, images: qualifRows }, companyInfo },
      });
      return modal.present();
    };

    return {
      // methods
      t,
      openBrowser, openQualificationModal,
      addResizeUrlParams,

      // icons
      compass, informationCircle, location, call, print, mail, documentText,
      scan, add, qrCodeOutline, close, trendingUp, construct, checkmarkDone,
      home, build, documentsOutline, peopleCircle, logoFacebook,

      // variables
      locale,
      currUser, userLoggedIn,
      tabSlideOps,
      loading, loadingUser,
      latestProjects, inProgressProjects, completedProjects,
      companyInfo, qualifications,
      involvingProjects, // projects that users working on (with job positions)
      projectsRelatedToUsers, userLocations,
      isMobileApp,

      // Scan QR code
      scanningQRCode, startScanLocationQRCode, stopScan,

      // swiper
      modules: [IonicSlides, Navigation, Pagination],

      // project list modal
      isProjectListModalOpened, selectedProjectType,
      getProjectsOfSelectedType: () => (store.getters.getProjectsByType(selectedProjectType.value)),
    }
  },
}
