import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_image_slides = _resolveComponent("image-slides")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.qualification ? _ctx.t(_ctx.qualification.title) : ""), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.qualification.title == '主要管理人員資歷及證書')
          ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_header, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.companyInfo.directorName), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        (_ctx.qualification.images)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_image_slides, {
                qualificationImages: _ctx.qualification.images
              }, null, 8, ["qualificationImages"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}