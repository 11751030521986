
// Vue reactivity
import { defineComponent, ref } from 'vue';

// icons
import { chevronBack, chevronForward, school, shieldCheckmark, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
        IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonItem, IonLabel,
        IonSpinner, IonImg, modalController } from '@ionic/vue';
import ImageSlides from '@/components/ImageSlides.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'QualificationModal',
  props: {
    qualification: {},
    companyInfo: {},
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
                IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonItem, IonLabel,
                IonImg, IonSpinner,
                ImageSlides, },
  setup(props) {
    console.log(props.qualification);
    
    const { t } = useI18n();
    const { closeModal } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      chevronBack, chevronForward, school, shieldCheckmark, close,

      // methods
      t, closeModal,
    }
  }
});
